<template>
  <secondment-create-wizard :resume="resumeWizard" :countries="countries"></secondment-create-wizard>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";

export default {
  components: {
    SecondmentCreateWizard: () => import( /* webpackChunkName: "create-SecondmentCreateWizard" */ "@/view/components/wizards/secondment-create-wizard/SecondmentCreateWizard.vue"),
  },

  beforeRouteEnter(_, from, next) {
    next((vm) => {
      vm.resumeWizard = false;
      if (from.query.pending) vm.resumeWizard = true;
    });
  },

  data() {
    return {
      resumeWizard: false,
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    wizardMode() {
      return this.$route.query.mode;
    },
  },

  watch: {
    wizardMode: {
      immediate: true,
      handler(val) {
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: this.$t("Assignments") },
          { title: val == "edit" ? this.$t("Edit") : this.$t("Create") },
        ]);
      },
    },
  }
};
</script>
