var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('secondment-create-wizard', {
    attrs: {
      "resume": _vm.resumeWizard,
      "countries": _vm.countries
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }